import React, { useEffect, useState } from "react"

// Libraries
import styled, { css } from "styled-components"
import { useTranslation } from "gatsby-plugin-react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container/"
import Observer from "components/observer/"

// Icons
import Caret from "assets/icons/icon-quotes-caret.svg"
import Arrow from "assets/icons/icon-design-sprint-arrow.inline.svg"

const StyledTestimonials = styled.section`
  padding-bottom: 104px;
  opacity: ${props => (props.visible ? "1" : "0")};
  transition: opacity .6s ease-in-out;
  overflow-x: hidden;

  ${breakpoint.medium`
    padding-bottom: 160px;
  `}

  .quotes-carousel {
    position: relative;
    display: flex;
    justify-content: space-between;
    overflow: visible;
    z-index: 50;

    ${breakpoint.medium`
      flex-wrap: wrap;
      padding: 24px 0;
    `}
  }

  .quote-wrapper {
    flex-shrink: 0;
    width: 100%;
    display: flex;
    align-items: flex-end;
    margin-right: 24px;
    transition: all 0.3s;

    &.end {
      ${breakpoint.medium`
        display: flex;
        align-items: flex-end;
     `}
    }

    ${breakpoint.small`
      margin-right: 48px;
    `}

    ${breakpoint.medium`
      width: calc((100% - 64px) / 3);
      align-items: flex-start;
      margin: 0 0 40px 0;
      transform: none!important;
    `}

    ${breakpoint.large`
      margin-bottom: 0;

      &:nth-child(1) {
        width: 448px;
      }

      &:nth-child(2) {
        width: 253px;
      }

      &:nth-child(3) {
        width: 352px;
      }
    `}
  }

  .navigation-arrows {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 16px;

    ${breakpoint.medium`
      display: none;
    `}

    .arrow {
      display: flex;
      transition: all 0.3s;

      &.arrow--right {
        transform: rotate(180deg);
      }

      &:disabled {
        opacity: 0.25;
        pointer-events: none;
      }

      svg {
        width: auto;
        height: 40px;

        * {
          transition: all 0.3s;
        }
      }

      &:hover {
        svg {
          * {
            fill: ${colors.supernova};
          }
        }
      }
    }
  }
`

const StyledQuote = styled.div`
  position: relative;
  padding: 24px;
  background-color: ${colors.indiblue};
  color: ${colors.white};
  font-size: 18px;
  font-weight: 400;
  border-radius: 8px;

  &::before {
    content: url("${Caret}");
    width: 42px;
    height: auto;
    position: absolute;
    top: 100%;
    left: 24px;
    display: block;
    
    ${breakpoint.medium`
      ${props =>
        props.top
          ? css`
              top: -30px;
              bottom: auto;
              transform: rotate(180deg);
            `
          : css`
              top: 100%;
              bottom: auto;
            `}

      ${props =>
        props.right
          ? css`
              right: 40px;
              left: auto;
            `
          : css`
              left: 78px;
              right: auto;
            `}
    `}
  }

  .author {
    font-size: 16px;
    font-weight: 700;
  }
`

const Quote = props => (
  <StyledQuote
    top={props.top}
    right={props.right}
    bottom={props.bottom}
    left={props.left}
  >
    <p>{props.text}</p>
    <br />
    <p className="author">{props.author}</p>
  </StyledQuote>
)

const Testimonials = props => {
  const [isVisible, setIsVisible] = useState(false)
  const [isCarouselEnabled, setIsCarouselEnabled] = useState(false)
  const [currentSlide, setCurrentSlide] = useState(1)

  useEffect(() => {
    if (window.innerWidth < 1024) {
      setIsCarouselEnabled(true)
    }
  }, [])

  const handleVisibility = () => {
    if (!isVisible) {
      setIsVisible(true)
    }
  }

  const handlePrev = () => {
    if (currentSlide > 1) {
      setCurrentSlide(-1)
    }
    handleSlideTransition()
  }

  const handleNext = () => {
    if (currentSlide < 6) {
      setCurrentSlide(+1)
    }
    handleSlideTransition()
  }

  const handleSlideTransition = () => {
    const slides = document.querySelectorAll(`.quote-wrapper`)
    const padding = window.innerWidth < 768 ? 24 : 48
    let slideTranslation = 0

    // Let's build the transition amount
    for (let x = 0; x < currentSlide - 1; x++) {
      slideTranslation = slideTranslation + slides[x].clientWidth + padding
    }

    slides.forEach(slide => {
      slide.style.transform = "translateX(-" + slideTranslation + "px)"
    })
  }
  const { t } = useTranslation()
  return (
    <StyledTestimonials visible={isVisible}>
      <Observer onVisible={handleVisibility} threshold="0.3">
        <Container>
          <div className="quotes-carousel">
            <div className="quote-wrapper">
              <Quote
                text={t(
                  "We are super excited about the designs and happy with the direction that's being taken.  I'm very satisfied!"
                )}
                author="Nicholas Tatonetti - Columbia University"
                right
                bottom
              />
            </div>
            <div className="quote-wrapper">
              <Quote
                text={t(
                  "They've been great at keeping us on track for our deadline, and I'm in love with their designs."
                )}
                author="Gage Holloway - True Funeral"
                right
                bottom
              />
            </div>
            <div className="quote-wrapper">
              <Quote
                text={t(
                  "The conference was an absolute success, and it was not possible without your team's support!"
                )}
                author="Shelly Kaur - ICAHN School of Medicine at Mount Sinai"
                bottom
                right
              />
            </div>

            <div className="quote-wrapper">
              <Quote
                text={t(
                  "They're super pragmatic, which I LOVE, and they're organized. I’m always just so surprised that everything gets done so quick. And it’s never a problem."
                )}
                author="Saskia Blank - Hasso Plattner Instititue"
                top
                left
              />
            </div>
            <div className="quote-wrapper end">
              <Quote
                text={t(
                  "Keeping the communication open was good, you feel like you're all on the same team."
                )}
                author="Haichen Wang - Cluey"
                top
                left
              />
            </div>
            <div className="quote-wrapper end">
              <Quote
                text={t(
                  "Since we’ve started working directly with Indicius, I don’t even consider alternative options. Your team is great to work with."
                )}
                author="Ashleigh Willmann - Dashing"
                top
                left
              />
            </div>
          </div>

          <div className="navigation-arrows">
            <button
              className="arrow arrow--left"
              onClick={handlePrev}
              disabled={setCurrentSlide === 1}
            >
              <Arrow />
            </button>
            <button
              className="arrow arrow--right"
              onClick={handleNext}
              disabled={setCurrentSlide === 6}
            >
              <Arrow />
            </button>
          </div>
        </Container>
      </Observer>
    </StyledTestimonials>
  )
}
export default Testimonials
