import React from "react"
import styled from "styled-components"

//Libraries
import { Trans } from "react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container/"
import LottieObserved from "components/lottie-observed/"

// Animation
import LocationAnimation from "assets/animations/about/animation-location.json"

const StyledContainer = styled(Container)`
  max-width: 932px;

  ${breakpoint.medium`
    display: flex;
  `}
`

const StyledLocation = styled.section`
  padding: 80px 0;
  background-color: ${colors.cream};

  ${breakpoint.medium`
    padding: 160px 0;
  `}

  .subtitle {
    margin-bottom: 16px;
    color: ${colors.indiblue};
    font-weight: 700;
    text-transform: uppercase;
  }

  .title {
    margin-bottom: 8px;
    font-weight: 500;

    + p {
      max-width: 752px;

      ${breakpoint.medium`
        margin-bottom: 14px;
      `}
    }
  }

  .location__icon {
    width: 74px;
    margin-bottom: 32px;

    ${breakpoint.medium`
      width: 140px;
      margin-right: 32px;
    `}
  }

  .location__content {
    ${breakpoint.medium`
      width: calc(100% - 172px);
    `}
  }
`

const Location = () => (
  <StyledLocation>
    <StyledContainer>
      <div className="location__icon">
        <LottieObserved animation={LocationAnimation} />
      </div>
      <div className="location__content">
        <h4 className="subtitle">
          <Trans>Based in Argentina</Trans>
        </h4>
        <p className="title">
          <Trans>We work with clients all over the world.</Trans>
        </p>
        <p>
          <Trans>
            Argentina is our home base. Time zones can be annoying, but we come
            up with creative solutions that make it work.
          </Trans>
        </p>
      </div>
    </StyledContainer>
  </StyledLocation>
)

export default Location
