import React from "react"
import styled from "styled-components"

//Labraries
import { Trans } from "react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container/"

// Icons
import Litebox from "assets/icons/about/icon-litebox.inline.svg"
import PurpleBunny from "assets/icons/about/icon-purple-bunny.inline.svg"
import Nk from "assets/icons/about/icon-nk.inline.svg"

const StyledContainer = styled(Container)`
  max-width: 924px;
  display: flex;
  flex-wrap: wrap;

  ${breakpoint.medium`
    flex-wrap: no-wrap;
    justify-content: space-between;
  `}
`

const StyledPartners = styled.section`
  padding: 80px 0;
  background-color: ${colors.midnight};
  color: ${colors.white};

  ${breakpoint.medium`
    padding: 82px 0;
  `}

  .partners__content {
    width: 100%;
    margin-bottom: 40px;

    ${breakpoint.medium`
      width: calc((100% - 40px) / 2);
      margin-bottom: 0;
    `}

    h2 {
      font-weight: 400;
      margin-bottom: 40px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .partner {
      position: relative;
      display: block;
      padding: 24px 82px 24px 24px;
      margin-bottom: 32px;
      font-size: 18px;
      border-radius: 3px;
      transition: all 0.3s ease;

      &:hover {
        transform: scale(1.02);
      }

      &:last-child {
        margin-bottom: 0;
      }

      &.litebox {
        background-color: #fc5b59;
      }

      &.purple-bunny {
        background-color: #5718a5;
      }

      &.nk {
        background-color: #00ffc2;
        color: ${colors.midnight};
      }

      .name {
        margin-bottom: 8px;
        font-weight: 700;
      }

      .partner__logo {
        position: absolute;
        top: 24px;
        right: 24px;
        display: flex;
      }
    }
  }
`

const Partners = () => (
  <StyledPartners>
    <StyledContainer>
      <div className="partners__content d-flex flex-column justify-content-center">
        <h2>
          <Trans>We choose our partners carefully.</Trans>
        </h2>
        <p>
          <Trans>
            We work with people who share our same beliefs and values about
            human-centered design.
          </Trans>
          <br />
          <br />
          <Trans>
            When the going gets tough, these are the people that will still be
            there for us and our clients.
          </Trans>
        </p>
      </div>
      <div className="partners__content">
        <a
          className="partner purple-bunny"
          href="https://purplebunny.co"
          target="_blank"
          rel="noopener noreferrer"
        >
          <p className="name">Purple Bunny</p>
          <p>
            <Trans>
              Meet our sister company that shines crafting designs for digital
              products.
            </Trans>
          </p>
          <br />
          <p>purplebunny.co</p>
          <div className="partner__logo">
            <PurpleBunny />
          </div>
        </a>

        <a
          className="partner litebox"
          href="https://litebox.ai"
          target="_blank"
          rel="noopener noreferrer"
        >
          <p className="name">
            <Trans>Litebox</Trans>
          </p>
          <p>
            <Trans>
              Our friends at Litebox help us develop amazing websites and apps.
            </Trans>
          </p>
          <br />
          <p>litebox.ai</p>
          <div className="partner__logo">
            <Litebox />
          </div>
        </a>

        <a
          className="partner nk"
          href="https://estudionk.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <p className="name">Estudio NK</p>
          <p>
            <Trans>
              We work with NK to create our most innovative, out-of-the-box
              projects.
            </Trans>
          </p>
          <br />
          <p>estudionk.com</p>
          <div className="partner__logo">
            <Nk />
          </div>
        </a>
      </div>
    </StyledContainer>
  </StyledPartners>
)

export default Partners
