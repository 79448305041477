import React from "react"
import styled from "styled-components"

import { Trans } from "react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container/"
import Observer from "components/observer/"

// Icons
import Samsung from "assets/icons/about/clients/samsung.inline.svg"
import AON from "assets/icons/about/clients/aon.inline.svg"
import HassoPlatner from "assets/icons/about/clients/hasso-plattner.inline.svg"
import Hitwise from "assets/icons/about/clients/hitwise.inline.svg"
import MercadoLibre from "assets/icons/about/clients/mercado-libre.inline.svg"
import Nubi from "assets/icons/about/clients/nubi.inline.svg"
import OSDE from "assets/icons/about/clients/osde.inline.svg"
import MountSinai from "assets/icons/about/clients/mount-sinai.inline.svg"
import Feedly from "assets/icons/about/clients/feedly.inline.svg"
import ColumbiaUniversity from "assets/icons/about/clients/columbia-university.inline.svg"
import ESPN from "assets/icons/about/clients/espn.inline.svg"
import RedBull from "assets/icons/about/clients/red-bull.inline.svg"
import Penn from "assets/icons/about/clients/penn.inline.svg"
import Character from "assets/icons/about/clients/character.inline.svg"
import Vinco from "assets/icons/about/clients/vinco.inline.svg"
import Bayton from "assets/icons/about/clients/bayton.inline.svg"
import MaintainX from "assets/icons/about/clients/maintain-x.inline.svg"
import MakingSense from "assets/icons/about/clients/making-sense.inline.svg"

const StyledClients = styled.section`
  padding: 80px 0 56px 0;
  text-align: center;

  ${breakpoint.medium`
    padding: 160px 0 104px 0;
  `}
  

  .title {
    margin-bottom: 32px;
    opacity: ${props => (props.visible ? "1" : "0")};
    transition: opacity .6s ease-in-out;

    ${breakpoint.medium`
      margin-bottom: 56px;
    `}

    h1 {
      margin-bottom: 16px;
    }
  }

  .clients {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .client {
      width: calc((100% - 24px) / 2);
      height: 112px;
      display: flex;
      justify-content: center;
      margin-bottom: 24px;
      opacity: ${props => (props.visible ? "1" : "0")};
      transition: opacity .6s ease-in-out;

      &:nth-last-child(-n + 2) {
        margin-bottom: 0;
      }

      ${breakpoint.small`
        width: calc((100% - (32px * 2)) / 3);

        &:nth-last-child(-n + 3) {
          margin-bottom: 0;
        }
      `}

      ${breakpoint.medium`
        width: calc((100% - (32px * 3)) / 4);
        margin-bottom: 56px;

        &:nth-last-child(-n + 4) {
          margin-bottom: 0;
        }
      `}

      ${breakpoint.large`
        width: calc((100% - (32px * 4)) / 6); 

        &:nth-last-child(-n + 6) {
          margin-bottom: 0;
        }
      `}
    }
  }
`

class Clients extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isVisible: false,
    }
  }

  handleVisibility = () => {
    if (!this.state.isVisible) {
      this.setState({
        isVisible: true,
      })
    }
  }

  render = () => (
    <StyledClients visible={this.state.isVisible}>
      <Container>
        <Observer onVisible={this.handleVisibility} threshold="0.1">
          <div className="title">
            <h1>
              <Trans>Teams we've helped</Trans>
            </h1>
            <p className="subtitle">
              <Trans>We work with big and small clients</Trans>
            </p>
          </div>

          <div className="clients">
            <div className="client">
              <Samsung />
            </div>
            <div className="client">
              <AON />
            </div>
            <div className="client">
              <HassoPlatner />
            </div>
            <div className="client">
              <MercadoLibre />
            </div>
            <div className="client">
              <Nubi />
            </div>
            <div className="client">
              <OSDE />
            </div>
            <div className="client">
              <MountSinai />
            </div>
            <div className="client">
              <Feedly />
            </div>
            <div className="client">
              <ColumbiaUniversity />
            </div>
            <div className="client">
              <ESPN />
            </div>
            <div className="client">
              <RedBull />
            </div>
            <div className="client">
              <Penn />
            </div>
            <div className="client">
              <Character />
            </div>
            <div className="client">
              <Vinco />
            </div>
            <div className="client">
              <Bayton />
            </div>
            <div className="client">
              <Hitwise />
            </div>
            <div className="client">
              <MaintainX />
            </div>
            <div className="client">
              <MakingSense />
            </div>
          </div>
        </Observer>
      </Container>
    </StyledClients>
  )
}

export default Clients
