import React from "react"

// Libraries
import styled, { keyframes } from "styled-components"
import { Trans } from "react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors, fonts } from "utils/variables/"

// Components
import Container from "components/container/"
import Modal from "components/modal/"
import Lottie from "components/lottie/"

// Animations
import Indiboy from "assets/animations/about/indiboy.json"

// Icons
import IndiDictionary from "assets/icons/icon-indi-dictionary.inline.svg"

const StyledContainer = styled(Container)`
  max-width: 736px;
`

const ModalContainer = styled(Container)`
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  ${breakpoint.small`
    height: auto;
  `}

  ${breakpoint.medium`
    position: relative;
  `}
`

const StyledModal = styled(Modal)`
  .modal__image {
    order: 0;
    width: 100%;
    margin-bottom: 24px;
    text-align: center;

    ${breakpoint.small`
      order: 1;
      width: calc(100% - 672px);
      margin-bottom: 0;
    `}

    img {
      max-width: 85%;

      ${breakpoint.small`
        max-width: 100%;
      `}
    }
  }

  .modal__content {
    max-width: 640px;
    order: 1;
    margin-bottom: 64px;

    ${breakpoint.small`
      order: 0;
    `}

    ${breakpoint.medium`
      margin-bottom: 0;
    `}

    h1 {
      margin-bottom: 0;
      color: ${colors.indiblue};
      font-family: ${fonts.primary};
      font-weight: 500;
    }

    .subtitle {
      color: ${colors.indiblue};
      font-family: ${fonts.primary};
      font-weight: 400;
      text-transform: lowercase;

      b {
        font-weight: 700;
      }
    }

    p {
      max-width: 544px;

      ${breakpoint.medium`
        font-size: 24px;
      `}
    }

    .dictionary {
      margin-top: 64px;

      ${breakpoint.large`
        position: absolute;
        left: 0;
        bottom: -120px;
        margin: 0;
      `}
    }
  }
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const StyledWeBelieve = styled.section`
  padding: 64px 0 88px;

  ${breakpoint.medium`
    padding: 160px 0;
  `}

  h1 {
    margin-bottom: 60px;

    ${breakpoint.medium`
      margin-bottom: 54px;
    `}
  }

  .definition {
    font-weight: 500;

    ${breakpoint.medium`
      margin: 12px 0;
    `}

    .definition__container {
      position: relative;

      button {
        height: 10px;
        position: absolute;
        color: ${colors.indiblue};
        font-size: 32px;
        line-height: 22px;
        animation: ${rotate} 3s linear infinite;
        cursor: pointer;
      }
    }
  }
`

class WeBelieve extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
    }
  }

  closeModal = () => {
    if (this.state.showModal) {
      this.setState(
        {
          showModal: false,
        },
        () => {
          document.querySelector("html").classList.remove("no-scroll")
          document.querySelector("body").classList.remove("no-scroll")
        }
      )
    }
  }

  openModal = event => {
    if (!this.state.showModal) {
      this.setState(
        {
          showModal: true,
        },
        () => {
          document.querySelector("html").classList.add("no-scroll")
          document.querySelector("body").classList.add("no-scroll")
        }
      )
    }
  }

  render = () => (
    <StyledWeBelieve>
      <StyledContainer>
        <h1>
          <Trans>
            Innovation happens when both our team and our clients can grow and
            thrive.
          </Trans>
        </h1>
        <p>
          <Trans>
            An object in motion stays in motion unless acted on by an outside
            force, right? Well there’s a lesser known saying that’s equally as
            important and earth-shattering:
          </Trans>
        </p>
        <br />
        <p className="definition">
          <span className="definition__container">
            <Trans>Designers in motion stay in motion.</Trans>
            <button type="button" onClick={this.openModal}>
              *
            </button>
          </span>
          <br />
          <Trans>And we’re in motion, baby. No signs of stopping.</Trans>
        </p>
        <br />
        <p>
          <Trans>
            We're constantly in motion, testing, pivoting and adapting to the
            pace of the industry and the needs of our clients and users.
          </Trans>
        </p>

        <StyledModal
          visible={this.state.showModal}
          closeFunction={this.closeModal}
        >
          <ModalContainer>
            <div className="modal__content">
              <h1>
                <Trans>Indicius</Trans>
              </h1>
              <p className="subtitle">
                <Trans>
                  <i>[In-dee-see-US]</i>{" "}
                  <b>
                    <Trans>noun.</Trans>
                  </b>
                </Trans>
              </p>
              <br />
              <p>
                <Trans>
                  In Spanish, the word “indicio” refers to a sign, hint, or
                  suggestion. It suggests that something’s about to happen.
                </Trans>
              </p>
              <br />{" "}
              <p>
                <Trans>
                  We came up with the name "Indicius" while surrounded by a
                  group of friends. It's only appropriate that that's who we
                  are. A group of friends who care about human-centered design
                  (some of our clients say "a family").
                </Trans>
              </p>
              <div className="dictionary">
                <IndiDictionary />
              </div>
            </div>
            <div className="modal__image">
              <Lottie autoplay loop animation={Indiboy} />
            </div>
          </ModalContainer>
        </StyledModal>
      </StyledContainer>
    </StyledWeBelieve>
  )
}

export default WeBelieve
