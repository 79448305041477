import React from "react"

// Utils
import { colors } from "utils/variables/"

// Libraries
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"

// Layout
import Layout from "layouts/layout-primary"

// Context
import ContextConsumer from "components/context/"

// Components
import Seo from "components/seo/"

// Page Sections
import Hero from "sections/about/hero"
import Carousel from "sections/about/carousel"
import WeBelieve from "sections/about/we-believe"
import OurCulture from "sections/about/our-culture"
import Clients from "sections/about/clients"
import Testimonials from "sections/about/testimonials"
import Partners from "sections/about/partners"
import Location from "sections/about/location"

const About = ({ data }) => {
  const { t } = useTranslation()
  return (
    <div>
      <Layout menuColor={colors.midnight}>
        <ContextConsumer>
          {({ data, set }) => {
            if (data.useYellowFooter) {
              set({ useYellowFooter: false })
            }
            if (data.darkMenu) {
              set({ darkMenu: false })
            }
            if (!data.showFooter) {
              set({ showFooter: true })
            }
            if (data.simplifiedFooter) {
              set({ simplifiedFooter: false })
            }
          }}
        </ContextConsumer>
        <Seo title={t("About - Indicius Design Agency")} />
        <Hero />
        <Carousel />
        <WeBelieve />
        <OurCulture />
        <Clients />
        <Testimonials />
        <Partners />
        <Location />
      </Layout>
    </div>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default About
