import React, { useState, useEffect } from "react"

// Libraries
import styled from "styled-components"
import Lottie from "react-lottie"
import { Trans, useTranslation } from "react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container/"
import Button from "components/button/"

// Animation
import Animation from "assets/animations/about/our-culture-animation.json"

const StyledOurCulture = styled.section`
  height: 720px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 60px 0;
  background-color: ${colors.midnight};

  .culture__animation {
    width: auto;
    height: 100%;
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 8;

    ${breakpoint.medium`
      display: block;
    `}
  }

  .culture__content {
    max-width: 340px;
    position: relative;
    margin: 0 auto;
    text-align: center;
    z-index: 10;

    h2 {
      max-width: 250px;
      margin: 0 auto 40px auto;
      color: ${colors.white};

      ${breakpoint.medium`
        max-width: 100%;
      `}
    }

    a {
      ${breakpoint.medium`
        background-color: ${colors.midnight};
        color: ${colors.supernova};

        &:hover {
          background-color: ${colors.supernova};
          color: ${colors.midnight};
        }
      `}
    }
  }
`

const OurCulture = () => {
  const [isMouseOver, setIsMouseOver] = useState(false)

  const handleMouseOver = () => {
    if (!isMouseOver) {
      setIsMouseOver(true)
    }
  }

  const handleMouseLeave = () => {
    if (isMouseOver) {
      setIsMouseOver(false)
    }
  }

  const options = {
    loop: false,
    autoplay: false,
    animationData: Animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }
  const { t } = useTranslation()
  return (
    <StyledOurCulture
      onMouseOver={handleMouseOver}
      onFocus={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <div className="culture__animation">
        <Lottie
          options={options}
          direction={isMouseOver ? 1 : -1}
          isClickToPauseDisabled={true}
        />
      </div>
      <Container>
        <div className="culture__content">
          <h2>
            <Trans>We care about our culture</Trans>
          </h2>
          <Button
            to="/culture/"
            text={t("Discover more")}
            backgroundColor={colors.supernova}
            color={colors.midnight}
            hoverBackgroundColor={colors.indiblue}
            hoverColor={colors.midnight}
          />
        </div>
      </Container>
    </StyledOurCulture>
  )
}

export default OurCulture
